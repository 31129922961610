export interface ApplicationsInterface {
  id: number;
  application: string;
  icon: string;
  domain?: string;
  email?: string;
  description: string;
  project_name?: string;
  connected: boolean;
  show?: boolean;
}

export interface HeaderTourInterface {
  title: string;
}
export interface ParamsInterface {
  placement: string;
}

export interface TourInterface {
  target: string;
  header: HeaderTourInterface;
  content: string;
  params: ParamsInterface;
}

export const TourApplication: TourInterface[] = [
  {
    target: 'Añade tus herramientas favoritas',
    header: {
      title: 'Get Started',
    },
    content: 'Ya puedes exportar tus controles a tus tableros de Jira',
    params: {
      placement: 'right',
    },
  },
]

export const ApplicationsOptions: ApplicationsInterface[] = [
  {
    id: 1,
    application: 'slack',
    icon: 'Slack',
    description: 'Recibe notificaciones acerca de tu estado de cumplimiento.',
    connected: false,
    show: false,
  },
  {
    id: 2,
    application: 'asana',
    icon: 'Asana',
    description: 'Exporta tus controles para planificar el cumplimiento.',
    connected: false,
    show: false,
  },
  {
    id: 3,
    application: 'clickUp',
    icon: 'ClickUp',
    description: 'Exporta tus controles para planificar el cumplimiento.',
    connected: false,
    show: false,
  },
  {
    id: 4,
    application: 'bitBucket',
    icon: 'BitBucket',
    description: 'Controla y actualiza el estado de seguridad de tus proyectos',
    connected: false,
    show: false,
  },
  {
    id: 5,
    application: 'gitHub',
    icon: 'GitHub',
    description: 'Controla y actualiza el estado de seguridad de tus proyectos',
    connected: false,
    show: false,
  },
  {
    id: 6,
    application: 'gitLab',
    icon: 'GitLab',
    description: 'Controla y actualiza el estado de seguridad de tus proyectos',
    connected: false,
    show: false,
  },
  {
    id: 7,
    application: 'jira',
    icon: 'Jira',
    description:
      'Exporta tus controles para distribuir las tareas al equipo y planificar el cumplimiento.',
    connected: false,
    show: true,
  },
]
