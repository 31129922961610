
import { Component, Vue, Prop, } from 'vue-property-decorator'
import { ApplicationsInterface, } from '@/utils/Applications'

@Component
export default class CardApplicationsComponent extends Vue {
  @Prop({ required: true, }) readonly element!: ApplicationsInterface

  private goConfiguration (element: ApplicationsInterface) {
    this.$store.commit('ApplicationModule/SET_APPLICATION_SELECTED', element)
    this.$router.push(`/startup/applications/${element.id}/configuration`)
  }

  private goAdd () {
    this.$router.push('/startup/applications/add')
  }

  get getApplicationDescription () {
    switch (this.element.application) {
    case 'asana':
      return 'Exporta tus actividades para distribuir las tareas al equipo y planificar el cumplimiento.'
    case 'jira':
      return 'Exporta tus actividades para distribuir las tareas al equipo y planificar el cumplimiento.'
    case 'slack':
      return 'Recibe notificaciones acerca de tu estado de cumplimiento.'
    case 'bitBucket':
      return 'Controla y actualiza el estado de seguridad de tus proyectos'
    case 'gitHub':
      return 'Controla y actualiza el estado de seguridad de tus proyectos'
    case 'gitLab':
      return 'Controla y actualiza el estado de seguridad de tus proyectos'
    case 'clickUp':
      return 'Exporta tus actividades para distribuir las tareas al equipo y planificar el cumplimiento.'

    default:
      return 'Exporta tus actividades para distribuir las tareas al equipo y planificar el cumplimiento.'
    }
  }

  get getImgApp () {
    switch (this.element.application) {
    case 'asana':
      return 'asana.svg'
    case 'jira':
      return 'jira.svg'
    case 'slack':
      return 'slack.svg'
    case 'bitBucket':
      return 'bitbucket.svg'
    case 'gitHub':
      return 'github.svg'
    case 'gitLab':
      return 'gitlab.svg'
    case 'clickUp':
      return 'asana.svg'

    default:
      return 'asana.svg'
    }
  }
}
