
import { Component, Vue, } from 'vue-property-decorator'
import CardApplication from '@/components/CardApplications/Index.vue'
import { ApplicationsOptions, ApplicationsInterface, } from '@/utils/Applications'

@Component({
  components: {
    CardApplication,
  },
})
export default class ApplicationsComponent extends Vue {
  private cards: ApplicationsInterface[] = []
  private applications: ApplicationsInterface[] = []

  mounted () {
    this.$mixpanel.track('Seccion Aplicaciones')
    this.cards = ApplicationsOptions
    // this.getApps()
  }

  // private getApps () {
  //   this.$store
  //     .dispatch('ApplicationModule/getApps')
  //     .then((response) => {
  //       this.applications = response.data
  //     })
  //     .catch(() => {
  //     })
  // }
}
